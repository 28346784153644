$(document).ready(function() {
    if (!window.location.getParameter ) {
        window.location.getParameter = function(key) {
            function parseParams() {
                let params = {},
                    e,
                    a = /\+/g,  // Regex for replacing addition symbol with a space
                    r = /([^&=]+)=?([^&]*)/g,
                    d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
                    q = window.location.search.substring(1);

                while (e = r.exec(q)) {
                    params[d(e[1])] = d(e[2]);
                }

                return params;
            }

            if (!this.queryStringParams) {
                this.queryStringParams = parseParams();
            }
            return this.queryStringParams[key];
        };
    }

    if(localStorage.getItem("resetPasswordSuccessMessage") !== null) {
        $("#successMessage").text(localStorage.getItem("resetPasswordSuccessMessage"));
        $("#successRow").show();
        localStorage.removeItem("resetPasswordSuccessMessage");
    } else {
        $("#successRow").hide();
    }

    if(localStorage.getItem("resetPasswordWarningMessage") !== null) {
        $("#warningMessage").text(localStorage.getItem("resetPasswordWarningMessage"));
        $("#warningRow").show();
        localStorage.removeItem("resetPasswordWarningMessage");
    } else {
        $("#warningRow").hide();
    }

    localStorage.clear();
    localStorage.setItem("buildId", $("#buildId").text());
    /*set current year to copyright section*/
    let currentYear = new Date().getFullYear();
    $('#dateContainer').html(currentYear);

    /*get stats and populate stats section*/
    $.get('/portal-rest/v1/stats').done(function(data) {
        if(data.entity.showStats) {
            let statsSection = $("#statsSection").empty();
            let productDetails =
                $("<div id='productDetails'>" +
                    "<div class='social_media_stats'>" +
                    "<h2>Social Media Stats</h2> " +
                    "<label class='header'>Total Feeds</label> " +
                    "<p class='header_stats'>" + data.entity.totalFeeds + "</p> " +
                    "<label class='header'>Posts Yesterday</label>" +
                    "<p class='header_stats'>" + data.entity.postsYesterday + "</p>" +
                    "</div>" +
                    "<div class='news_stats'>" +
                    "<h2>News Stats</h2>" +
                    "<label class='header'>Total Sources</label>" +
                    "<p class='header_stats'>" + data.entity.totalSources + "</p>" +
                    " <label class='header'>Articles Yesterday</label>" +
                    "<p class='header_stats'>" + data.entity.articlesYesterday + "</p>" +
                    "</div>" +
                    "</div>" +
                    "<br>" +
                    "<div class='stats_message'>" +
                    "<p id='detailsUpdated'>Updated " + data.entity.timeSinceUpdated + ". Log in for detailed statistics and source lists.</p>" +
                    "</div>");

            productDetails.appendTo(statsSection);
        }
    });

    function showErrorMessage(message) {
        $("#errorMessage").html(message);
        $("#successRow").hide();
        $("#warningRow").hide();
        $("#errorRow").show();
    }
    /*login action*/
    $("#loginSubmit").click(function(){
        $("#errors").addClass('d-n').empty();
        let errorMessage = "The username or password that you entered don't match, please try again.";

        $.ajax({
            type: "POST",
            url: "rest/login/login-processing",
            data: $("#loginForm").serialize(),
            success: function(response, statusText, xhr, $form)  {
                if (response != null && response.success) {
                    if (response.forceTermsAndConditions) {
                        window.location.href = '/termsAndConditions.html';
                    } else {
                        window.location.href = '/index.html';
                    }
                } else if (response != null && response.forcePasswordChange) {
                    showErrorMessage("For security reasons, you must update your password. <a href=\"/resetPassword.html\">Click here</a> to reset your password.");
                } else {
                    if ($("#username").val().includes("@")) {
                        errorMessage +=
                            " Your username might not be the same as your email address. If you do not know your username, it will be sent to you if you reset your password.";
                    }
                    showErrorMessage(errorMessage);
                }
            },
            error: function(response, statusText, error, $form)  {
                showErrorMessage(errorMessage);
            }
        });
        return false;
    })
});