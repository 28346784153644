$.urlParam = function (name) {
    let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results === null) {
        return null;
    }
    else {
        return results[1] || 0;
    }
};

$(document).ready(function () {
    let uuid = $.urlParam('uuid');

    if (uuid === null) {
        $('.reset-pass-step-2').hide();
    } else {
        $('.reset-pass-step-1').hide();

        $.ajax({
            url: '/portal-rest/v1/user/checkUuid/' + uuid,
            type: 'GET',
            success: function () {
            },
            error: function (data) {
                let json = JSON.parse(data.responseText);
                localStorage.setItem("resetPasswordWarningMessage", json.entity);
                window.location.href = '/login.html';
            }
        });

    }
});

window.checkEmail = () => {
    let val = $("#emailAddress").val();

    if (val.match(/\S+@\S+\.\S+/) && val.indexOf(' ') === -1 && val.indexOf('..') === -1) {
        let url = "/portal-rest/v1/user/sendEmail";
        let loadingContainer = $('.loading-container');
        showLoading(loadingContainer);
        let resetPasswordButton = $("#resetPasswordStep1");
        disableButtonHandler(resetPasswordButton);
        $.post(url, {email: val})
            .done(function (data) {
                localStorage.setItem("resetPasswordSuccessMessage", data.entity);
                window.location.href = "/login.html";
            })
            .fail(function (data) {
                let json = JSON.parse(data.responseText);
                $("#errorMessage").html(json.entity);
                $("#errorRow").show();
                hideLoading(loadingContainer);
                enableButtonHandler(resetPasswordButton);
            });
    } else {
        $("#errorMessage").text("The email address you have entered is not valid.");
        $("#errorRow").show();
    }
};

window.changePassword = () => {
    let newPassword = $("#newPassword").val();
    let confirmPassword = $("#confirmPassword").val();

    if (newPassword === confirmPassword) {
        let loadingContainer = $('.loading-container');
        showLoading(loadingContainer);
        let resetPasswordButton = $("#resetPasswordStep2");
        disableButtonHandler(resetPasswordButton);
        let uuid = $.urlParam('uuid');
        let url = "/portal-rest/v1/user/resetPassword/" + uuid;
        $.post(url, {newPassword: newPassword})
            .done(function (data) {
                localStorage.setItem("resetPasswordSuccessMessage", data.entity);
                window.location.href = "/login.html";
            })
            .fail(function (data) {
                let json = JSON.parse(data.responseText);
                $("#errorMessage").html(json.entity);
                $("#errorRow").show();
                hideLoading(loadingContainer);
                enableButtonHandler(resetPasswordButton);
            });
    } else {
        $("#errorMessage").text("The passwords do not match.");
        $("#errorRow").show();
    }
};

function disableButtonHandler(button) {
    button.attr('disabled','disabled');
    button.addClass("login_disabled_button");
    button.removeClass("login_primary_button");
}

function enableButtonHandler(button) {
    button.removeAttr("disabled");
    button.addClass("login_primary_button");
    button.removeClass("login_disabled_button");
}

function showLoading(container) {
    let loading = $("<img class='loading' src='../images/ajax_loader_large.gif'>");
    $(container).append(loading);
}

function hideLoading(container) {
    $(container).empty();
}